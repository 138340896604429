import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

/**
 * Base
 */

// Canvas
const canvas = document.querySelector("canvas.webgl");
const container = document.querySelector(".thumbnail");

// Scene
const scene = new THREE.Scene();

// axes helper

// const axesHelper = new THREE.AxesHelper(4);
// scene.add(axesHelper);

//
// Models
//

// use a loading manager to show loading
const gltfloader = new GLTFLoader();
let mutt;
gltfloader.load(
	"/models/Mutt/scene.gltf",
	(gltf) => {
		console.log("fully loaded");
		mutt = gltf.scenes[0].children[0];
		mutt.position.set(0, 0, 0);
		mutt.rotation.z = Math.PI * 1.5;
		// console.log(mutt);
		scene.add(mutt);
	},
	() => {
		// console.log("gltf loading");
	},
	() => {
		console.log("gltf error");
	}
);

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6);
directionalLight.castShadow = true;
directionalLight.shadow.mapSize.set(1024, 1024);
directionalLight.shadow.camera.far = 15;
directionalLight.shadow.camera.left = -7;
directionalLight.shadow.camera.top = 7;
directionalLight.shadow.camera.right = 7;
directionalLight.shadow.camera.bottom = -7;
directionalLight.position.set(5, 5, 5);
scene.add(directionalLight);

/**
 * Sizes
 */
const sizes = {
	width: window.innerWidth,
	height: window.innerHeight,
};

window.addEventListener("resize", () => {
	// Update sizes
	sizes.width = window.innerWidth;
	sizes.height = window.innerHeight;

	// Update camera
	camera.aspect = sizes.width / sizes.height;
	camera.updateProjectionMatrix();

	// Update renderer
	renderer.setSize(sizes.width, sizes.height);
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100);
camera.position.set(0, 0.5, 1);
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.target.set(0, 0.2, 0);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
	canvas: canvas,
	alpha: true,
});
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const clock = new THREE.Clock();
let previousTime = 0;

const tick = () => {
	const elapsedTime = clock.getElapsedTime();
	const deltaTime = elapsedTime - previousTime;
	previousTime = elapsedTime;
	const oscillation = Math.sin(elapsedTime);

	// Define the baseline distance and oscillation amplitude
	const baselineDistance = 0;
	const amplitude = 0.1;

	// Animate the mutt model if it's loaded
	if (mutt) {
		// Convert 30 degrees to radians
		const angle = THREE.MathUtils.degToRad(120);
		// mutt.rotation.z = angle * oscillation + Math.PI * 1.5;
		mutt.rotation.z = elapsedTime * 0.1;
		mutt.position.z = baselineDistance + amplitude * oscillation;
	}

	// Update the camera position
	// camera.position.z = baselineDistance + amplitude * oscillation;

	// Update controls
	controls.update();

	// Render
	renderer.render(scene, camera);

	// Call tick again on the next frame
	window.requestAnimationFrame(tick);
};

tick();
